<template>
  <v-container fluid class="down-top-padding">
    <base-breadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></base-breadcrumb>
    <base-card heading="Questões" :button="button">
      <list-question />
    </base-card>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  data: () => ({
    page: {
      title: 'Questões'
    },
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Questões',
        disabled: false,
        to: '/question/list'
      }
    ],
    button: {
      show: true,
      url: '/question/add',
      text: 'Questão',
      icon: 'mdi-plus'
    },
    text: {
      searchLabel: 'Pesquisar Questões',
      emptyLabel: 'Nenhuma questão encontrada',
      addLabel: 'Adicionar Questão',
      addUrl: '/question/add',
      importLabel: 'Importar Questões',
      importUrl: '/question/add'
    }
  }),
  methods: {
    ...mapMutations('exams', ['CLEAR_SELECTED'])

  },
  destroyed () {
    this.CLEAR_SELECTED()
  }
}
</script>
